import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CodigopadresService {

  constructor(private firestore: AngularFirestore) { }

  getCodigos() {
    return this.firestore.collection('codigospadres').snapshotChanges();
  }

  getCodigoById(id) {
    return this.firestore.collection('codigospadres').doc(id).snapshotChanges();
  }

  crearCodigo(codigo) {
    return this.firestore.collection('codigospadres').add(codigo);
  }

  updateCodigo(id, codigo) {
    return this.firestore.collection('codigospadres').doc(id).update(codigo);
  }

  searchCodigo(codigo) {
    return this.firestore.collection('codigospadres', ref => ref.where('codigo', '==' , codigo)).snapshotChanges();
  }

  searchCodigoUsuario(uid) {
    return this.firestore.collection('codigospadres', ref => ref.where('hijo', '==' , uid)).snapshotChanges();
  }
  searchCodigoUsuarioSinUso(uid) {
    return this.firestore.collection('codigospadres', ref => ref.where('hijo', '==' , uid).where('usado', '==' , false)).snapshotChanges();
  }

  searchCodigoNow(codigo) {
    return this.firestore.collection('codigospadres', ref => ref.where('codigo', '==' , codigo)).get();
  }
  
}